import React from "react";
import CallToAction from "../components/CallToAction";

const VotreOsteopathe = () => {
  return (
    <div className="main">
      <CallToAction />
    </div>
  );
};

export default VotreOsteopathe;
